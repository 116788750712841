// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "y_tq d_gS d_cw d_dv";
export var quoteParagraphCenter = "y_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "y_gV d_gV d_cw d_dx";
export var quoteRowLeft = "y_tr d_bG";
export var quoteRowCenter = "y_ts d_bD";
export var quoteRowRight = "y_tt d_bH";
export var quoteWrapper = "y_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "y_gR d_gR";
export var quoteExceptionSmall = "y_tv C_tv";
export var quoteExceptionNormal = "y_tw C_tw";
export var quoteExceptionLarge = "y_tx C_tx";
export var quoteAuthorExceptionSmall = "y_ty C_ty";
export var quoteAuthorExceptionNormal = "y_tz C_tz";
export var quoteAuthorExceptionLarge = "y_tB C_tB";