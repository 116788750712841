// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sF d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sG d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sH d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sJ d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sK d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sL d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_sM d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_sN C_sN";
export var heroExceptionNormal = "v_sP C_sP";
export var heroExceptionLarge = "v_sQ C_sQ";
export var Title1Small = "v_sR C_sR C_tH C_tJ";
export var Title1Normal = "v_sS C_sS C_tH C_tK";
export var Title1Large = "v_sT C_sT C_tH C_tL";
export var BodySmall = "v_sV C_sV C_tH C_t0";
export var BodyNormal = "v_sW C_sW C_tH C_t1";
export var BodyLarge = "v_sX C_sX C_tH C_t2";