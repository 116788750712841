// extracted by mini-css-extract-plugin
export var dark = "r_rj";
export var darkcookie = "r_rk";
export var tintedcookie = "r_rl";
export var regularcookie = "r_rm";
export var darkbase = "r_rn";
export var tintedbase = "r_rp";
export var regularbase = "r_rq";
export var darkraw = "r_rr";
export var tintedraw = "r_rs";
export var regularraw = "r_rt";
export var darkchick = "r_rv";
export var tintedchick = "r_rw";
export var regularchick = "r_rx";
export var darkherbarium = "r_ry";
export var tintedherbarium = "r_rz";
export var regularherbarium = "r_rB";
export var darkholiday = "r_rC";
export var tintedholiday = "r_rD";
export var regularholiday = "r_rF";
export var darkoffline = "r_rG";
export var tintedoffline = "r_rH";
export var regularoffline = "r_rJ";
export var darkorchid = "r_rK";
export var tintedorchid = "r_rL";
export var regularorchid = "r_rM";
export var darkpro = "r_rN";
export var tintedpro = "r_rP";
export var regularpro = "r_rQ";
export var darkrose = "r_rR";
export var tintedrose = "r_rS";
export var regularrose = "r_rT";
export var darktimes = "r_rV";
export var tintedtimes = "r_rW";
export var regulartimes = "r_rX";
export var darkwagon = "r_rY";
export var tintedwagon = "r_rZ";
export var regularwagon = "r_r0";