// extracted by mini-css-extract-plugin
export var alignLeft = "x_qt d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_qv d_fr d_bH d_dx";
export var productsContainer = "x_s4 d_dW";
export var productsTextWrapper = "x_s5 d_0 d_9 d_w d_cG";
export var productsImageElement = "x_lF";
export var productsModalWrapper = "x_s6 d_bz d_bL";
export var productsModalWrapperDesign3 = "x_l1 d_l1 d_bz d_bM d_bT d_bD d_cG d_bf";
export var modalLeftColumn = "x_s7 d_r d_H";
export var modalLeftColumnDesign2 = "x_s8 d_r d_H";
export var modalCloseIcon = "x_s9 d_dB";
export var modalRightColumn = "x_tb d_r d_H d_bT d_bz d_bM d_cG";
export var productsComponentsWrapperLeft = "x_lY d_lY d_y d_w d_cv d_dB";
export var modalCloseButton = "x_tc d_w d_bz d_bL d_bP d_bH";
export var modalCloseButtonDesign3 = "x_td d_w d_bz d_bL d_bP d_bH";
export var modalTextWrapperDesign3 = "x_tf d_H d_bz d_bM d_bP";
export var modalTextWrapper = "x_tg d_bz d_bM d_bP";
export var modalCarouselWrapper = "x_th d_H d_bz d_bL d_bD";
export var carouselWrapper = "x_k6 d_H d_bz d_bL d_bD";
export var productsCarouselImageWrapper = "x_tj d_lS d_H d_w";
export var productsCarouselImageWrapperSides = "x_tk d_H";
export var productsCarouselImageWrapperDesign3 = "x_lT d_lT d_bf";
export var productsCarouselWrapper = "x_lV d_lV d_bz d_bD d_bP d_w d_H";
export var productsCarouselWrapperDesign3 = "x_lW d_lW d_bz d_bD d_bP d_w";
export var productsCarouselImg = "x_mX d_lX d_w d_H d_bR";
export var productsImageModalWrapper = "x_lH";
export var productsImageElementDesign3 = "x_lJ d_lJ d_hH d_w d_H d_bR";
export var productsContainerFull = "x_tl d_dT";
export var productsMainHeader = "x_l3 d_l3 d_l2 d_w d_ct";
export var productsMainSubtitle = "x_l4 d_l4 d_l2 d_w d_cy";
export var productsComponentHeading = "x_l5 d_l5";
export var productsComponentParagraph = "x_l6 d_l6";
export var productsCard = "x_lK d_lK d_bd d_0 d_8 d_7 d_4 d_9 d_by d_y d_W d_H";
export var productsCardWrapper = "x_lL d_lL d_by d_w d_Z";
export var productsCardWrapperDesign2 = "x_lM d_lM d_w d_H d_bz d_bM d_bT";
export var productsTextBlockDesign2Wrapper = "x_tm d_cG";
export var productsTextBlockDesign3Wrapper = "x_lN d_lN d_w d_bz d_bM d_bD d_bP d_cG d_fn d_0";
export var productsTextBlockDesign2 = "x_lP d_lP d_bz d_bM d_H";
export var productsTextBlockDesign3 = "x_lQ d_lQ d_bz d_bM d_bP d_bl";
export var productsComponentsWrapperDesign2 = "x_lZ d_lZ d_y d_w d_bz d_bM d_cv d_bT";
export var productsComponentsWrapperDesign3 = "x_l0 d_l0 d_y d_w d_bz d_bM d_cv d_dB";
export var colEqualHeights = "x_tn d_bz";
export var productsImageWrapperLeft = "x_lB d_lB d_w d_Z d_bx";
export var productsImageWrapperDesign2 = "x_lC d_lC d_w";
export var design2TextWrapper = "x_tp d_r d_bC d_bP d_bJ";
export var exceptionWeight = "x_sD C_t7";