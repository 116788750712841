// extracted by mini-css-extract-plugin
export var lbContainerOuter = "G_wC";
export var lbContainerInner = "G_wD";
export var movingForwards = "G_wF";
export var movingForwardsOther = "G_wG";
export var movingBackwards = "G_wH";
export var movingBackwardsOther = "G_wJ";
export var lbImage = "G_wK";
export var lbOtherImage = "G_wL";
export var prevButton = "G_wM";
export var nextButton = "G_wN";
export var closing = "G_wP";
export var appear = "G_wQ";